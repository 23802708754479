import i18next from "i18next";
import React from "react";
import {connect} from "react-redux";
import {Close} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {setSos, setSosChoice} from "../../../redux/actions/sosActions";
import "./../../../style/sos.css"
import fight from "./../../../assets/images/fight.png"
import glass from "./../../../assets/images/glass.png"
import ice from "./../../../assets/images/ice.png"
import info from "./../../../assets/images/info.png"
import {setLoading} from "../../../redux/actions/globalActions";
import {toast} from "react-toastify";


const image = []
image['fight'] = fight;
image['glass'] = glass;
image['ice'] = ice;
image['info'] = info;

const Sos = (props) => {


    const help = () => {

        if (!props.sosChoice) {
            return;
        }

        setLoading(i18next.t("global.sendSos"))

        props.request("sos/ask", {choice: props.sosChoice}, result => {
            toast.success(i18next.t("global.sentSos"))
            setLoading(null)
            setSosChoice(null);
            setSos(false);
        })

    };

    const showSos = (sos, bg) => (
        <Grid item xs={6}>
            <div className={"waiterButtons"}>
                <Link onClick={() => {
                    setSosChoice(sos)
                }}>
                    <img alt={"ice"} style={{objectFit: "contain", backgroundColor: bg}}
                         className={
                             !props.sosChoice ? ("waiterButtonsImages") : (props.sosChoice === sos ? "waiterButtonsImages selected" : "waiterButtonsImages notSelected")
                         }
                         src={image[sos]}
                    />
                    <div className={
                        !props.sosChoice ? ("waiterButtonsLegends") : (props.sosChoice === sos ? "waiterButtonsLegends selectedLegend" : "waiterButtonsLegends notSelectedLegend")
                    }>
                        {i18next.t(`global.${sos}`)}
                    </div>
                </Link>
            </div>
        </Grid>
    )

    return (
        <>
            <Dialog maxWidth={"xl"} fullWidth={true} onClose={() => {
                setSos(false);
                setSosChoice(null)
            }} aria-labelledby="simple-dialog-title" open={props.sos} className={"dialogSOS"}
                    PaperProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255,0)',
                            boxShadow: 'none',
                            height: "100%"
                        },
                    }}
            >
                <DialogTitle style={{textAlign: "center"}}><span
                    style={{
                        fontSize: "1.8em",
                        color: "white",
                    }}
                >{i18next.t("global.sosTitle")}</span></DialogTitle>

                <Link onClick={() => setSos(false)}>
                    <div className={"closeDialog"}>
                        <Close style={{fontSize: "2em"}}/>
                    </div>
                </Link>

                <Grid container direction="row" className={"sosContainer"}
                      justifyContent="center" spacing={2}
                      alignItems="center">

                    {/*showSos("ice", "#f8c8c0")*/}
                    {/*showSos("glass", "#f8c8c0")*/}
                    {showSos("fight", "#f8c8c0")}
                    {showSos("info", "#9e9eca")}

                </Grid>

                <DialogActions>
                    {props.sosChoice && <Link onClick={help}>
                        <div className={"buttonAskHelp"}>{i18next.t('global.askHelp')}</div>
                    </Link>}
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    sos: state.sos.sos,
    sosChoice: state.sos.choice,
    request: state.request.request
});

export default connect(mapStateToProps)(Sos)
