import React from 'react';
import ReactDOM from 'react-dom';
import {Store} from './redux/Store';
import {Provider} from 'react-redux';
import './style/index.css'
import App from "./app/App";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={Store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
