import React from "react";
import {connect} from "react-redux";
import i18next from "i18next";
import {resetSession, setError} from "../../redux/actions/globalActions";
import "../../style/template.css"
import Categories from "./Categories";
import {timestampTimeout} from "../helper/global";

const Home = props => {

    React.useEffect(() => {
        if (props.timestamp) {
            const date = new Date()
            const timestamp = (date.getTime() / 1000).toFixed();
            if (props.timestamp && timestamp - props.timestamp > timestampTimeout) {
                setError(i18next.t('error.scanQR'))
                resetSession()
            } else if (!props.timestamp) {
                setError(i18next.t('error.scanQR'))
                resetSession()
            }
        }
    }, [props.timestamp])

    React.useEffect(() => {
        if (!props.id_establishment || !props.id_table || !props.token) {
            setError(i18next.t('error.error'))
        } else setError(null)
    }, [props.request])

    return <>
        {
            props.menu && props.id_establishment && (
                <>
                    <div className={"container"}>
                        <div className={"wave1"} style={{
                            background: `linear-gradient(${props.colors.wave1},${props.colors.wave1}) no-repeat 0px 73px`
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                <path fill={props.colors.wave1}
                                      d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
                            </svg>
                        </div>

                        <div className={"wave2"} style={{
                            background: `linear-gradient(${props.colors.wave2},${props.colors.wave2}) no-repeat 0px 73px`
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                <path fill={props.colors.wave2}
                                      d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
                            </svg>
                        </div>

                        <div className={"wave3"} style={{
                            background: `linear-gradient(${props.colors.wave3},${props.colors.wave3}) no-repeat 0px 73px`
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                                <path fill={props.colors.wave3}
                                      d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,234.7C560,256,640,256,720,250.7C800,245,880,235,960,229.3C1040,224,1120,224,1200,197.3C1280,171,1360,117,1400,90.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"/>
                            </svg>
                        </div>
                    </div>

                    <Categories/>

                </>
            )
        }

    </>
}

const mapStateToProps = (state) => ({
    id_table: state.global.id_table,
    id_establishment: state.global.id_establishment,
    menu: state.global.menu,
    timestamp: state.global.timestamp,
    token: state.global.token,
    name_establishment: state.global.name_establishment,
    request: state.request.request,
    colors: state.colors.colors,
});

export default connect(mapStateToProps)(Home)