import React from "react";
import {connect} from "react-redux";
import ErrorSvg from "./../../../assets/images/error.svg"

const Error = props => {
    return <>
        {props.global.error && (
            <>
                <div className="divLoaderBg"/>
                <div className="logo"/>
                <div className={"svgContainer"}><img alt="Error" className="svgError" src={ErrorSvg} height={200}
                                                     width={200}/></div>
                <div className={"textInit"}>
                    {props.global.error}
                </div>
            </>
        )}
    </>
}

const mapStateToProps = (state) => ({
    global: state.global
});

export default connect(mapStateToProps)(Error)