import {SET_REQUEST} from "../actions/requestActions";

const initialState = {
    request: null
};

export default function requestReducer(state = initialState, action) {
    switch (action.type) {
        case SET_REQUEST : {
            return {
                ...state,
                request: action.value
            };
        }
        default:
            return state
    }
}
