import {Store} from '../Store'
import i18next from "i18next";
import {setColors} from "./colorsActions";
import {defaultColors} from "../../app/helper/global";

export const SET_ID_ESTABLISHMENT = "SET_ID_ESTABLISHMENT"
export const SET_ID_TABLE = "INCREMENT_LAUNCH_COUNT"
export const SET_TOKEN = "SET_TOKEN"
export const SET_MENU = "SET_MENU"
export const SET_ERROR = "SET_ERROR"
export const SET_LOADING = "SET_LOADING"
export const SET_LOGO_ESTABLISHMENT = "SET_LOGO_ESTABLISHMENT"
export const SET_NAME_ESTABLISHMENT = "SET_NAME_ESTABLISHMENT"
export const SET_NAME_TABLE = "SET_NAME_TABLE"
export const SET_TIMESTAMP = "SET_TIMESTAMP"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_CART = "SET_CART"
export const SET_CURRENT_MENU = "SET_CURRENT_MENU"
export const SET_CURRENCY = "SET_CURRENCY"

export const resetSession = () => {
    setToken(null);
    setIdEstablishment(null);
    setIdTable(null);
    setNameEstablishment(null)
    setLogoEstablishment(null)
    setNameTable(null)
    setTimestamp(null)
    setMenu(null)
    setCurrency(null)
    setColors(defaultColors)
    localStorage.removeItem("name_table")
    localStorage.removeItem("timestamp")
    localStorage.removeItem("logo_establishment")
    localStorage.removeItem("name_establishment")
    localStorage.removeItem("id_establishment")
    localStorage.removeItem("id_table")
    localStorage.removeItem("token")
    localStorage.removeItem("menu")
    localStorage.removeItem("currency")
    localStorage.removeItem("colors")
}

export const setNameTable = (value) => {
    localStorage.setItem("name_table", value) ;
    Store.dispatch({ type: SET_NAME_TABLE, value})
}

export const setCurrency = (value) => {
    localStorage.setItem("currency", value) ;
    Store.dispatch({ type: SET_CURRENCY, value})
}

export const setCart = (value) => {
    Store.dispatch({ type: SET_CART, value})
}

export const setCurrentMenu = (value) => {
    Store.dispatch({ type: SET_CURRENT_MENU, value})
}

export const setLanguage = (value) => {
    i18next.changeLanguage(value)
    localStorage.setItem("language", value)
    Store.dispatch({type: SET_LANGUAGE, value: value})
}

export const setTimestamp = (value) => {
    localStorage.setItem("timestamp", value) ;
    Store.dispatch({ type: SET_TIMESTAMP, value})
}

export const setLogoEstablishment = (value) => {
    localStorage.setItem("logo_establishment", value) ;
    Store.dispatch({ type: SET_LOGO_ESTABLISHMENT, value})
}

export const setNameEstablishment = (value) => {
    localStorage.setItem("name_establishment", value) ;
    Store.dispatch({ type: SET_NAME_ESTABLISHMENT, value})
}

export const setIdEstablishment = (value) => {
    localStorage.setItem("id_establishment", value) ;
    Store.dispatch({ type: SET_ID_ESTABLISHMENT, value})
}

export const setLoading = (value) => {
    Store.dispatch({ type: SET_LOADING, value})
}

export const setError = (value) => {
    Store.dispatch({ type: SET_ERROR, value})
}

export const setIdTable = (value) => {
    localStorage.setItem("id_table", value) ;
    Store.dispatch({ type: SET_ID_TABLE, value})
}

export const setMenu = (value) => {
    Store.dispatch({ type: SET_MENU, value})
}

export const setToken = (value) => {
    localStorage.setItem("token", value) ;
    Store.dispatch({ type: SET_TOKEN, value})
}
