import React, { Component } from 'react';
import {LinearProgress} from "@mui/material";
import { withStyles } from '@mui/styles';

class ColoredLinearProgress extends Component {
    render() {
        const { classes } = this.props;
        return <LinearProgress {...this.props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>;
    }
}

const styles = props => ({
    colorPrimary: {
        backgroundColor: "rgba(246,188,65,0.15)",
    },
    barColorPrimary: {
        backgroundColor: "rgb(246, 188, 65)",
    }
});

export default  withStyles(styles)(ColoredLinearProgress);