import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCVkmEm2IbKtdGpNCMTBFuMgnr31KujqQA",
    authDomain: "evenight-46984.firebaseapp.com",
    databaseURL: "https://evenight-46984.firebaseio.com",
    projectId: "evenight-46984",
    storageBucket: "evenight-46984.appspot.com",
    messagingSenderId: "105182470158",
    appId: "1:105182470158:web:eab30263b4e513e166dc5f",
    measurementId: "G-L88BVF6TGZ"
};

initializeApp(firebaseConfig);
export const messaging = getMessaging();

export const getTokenFb = (setTokenNotif) => {
    return getToken(messaging, {vapidKey: 'BLSQ9LcKkCG16Fn11PVNUid8dLjz9i4o3Xf03qhSx7n1ZT_T4789yVM0iOVR9iHf_NHMmDyq0NAVqYn7uZZkTH0'}).then((currentToken) => {
        if (currentToken) {
            setTokenNotif(currentToken);
        } else {
            setTokenNotif(null);
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}