import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import "../../../style/cart.css"
import styled from "@emotion/styled";
import grey from "@mui/material/colors/grey";
import Box from "@mui/material/Box";
import {Global} from "@emotion/react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {setCart} from "../../../redux/actions/globalActions";
import CloseIcon from '@mui/icons-material/Close';
import ArticleCart from "./ArticleCart";
import i18next from "i18next";
import {AdsLink, CART_STATE} from "../../helper/global";
import Link from "@mui/material/Link";
import ColoredLinearProgress from "./ColoredLinearProgress";
import {toast} from "react-toastify";
import {useLocation} from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import IconButton from "@mui/material/IconButton";

const drawerBleeding = 35;

const Root = styled('div')(({theme}) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : grey[800],
}));

const Puller = styled(Box)(({theme}) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const Cart = props => {

    const [loadingBackdrop, setLoadingBackdrop] = useState(false)
    const [data, setData] = useState({})
    const [state, setState] = useState(null)
    const [askBillModal, setAskBillModal] = useState(false)
    const [announcement, setAnnouncement] = useState(null)
    const [closableAnnnouncement, setClosableAnnnouncement] = useState(5)
    const closeTimeout = useRef(5) ;
    const {window} = props;
    const location = useLocation()

    // Check to put the good submenu title
    let lastStateArticle = null;

    const toggleDrawer = (newOpen) => () => {
        setCart(newOpen);
    };

    const closableAnnnouncementTimeout = () => {
        if(closeTimeout.current > 0) {
            setTimeout(() => {
                closeTimeout.current -= 1
                setClosableAnnnouncement(closeTimeout.current);
                closableAnnnouncementTimeout()
            }, 1000)
        }
    }

    const validateCart = () => {
        setLoadingBackdrop(true)
        props.request("cart/validate", null, result => {
            console.log(result.data.media_link)

            if (result.data.media_link) {
                closeTimeout.current = 5 ;
                setClosableAnnnouncement(5)
                closableAnnnouncementTimeout()
                setAnnouncement(result.data.media_link)
            }

            toast.dismiss("infoCart")
            refresh()
            setLoadingBackdrop(false)
        }, null, () => setLoadingBackdrop(false))
    }

    const askBill = (type_bill) => {
        setLoadingBackdrop(true)
        props.request("cart/askBill", {type_bill}, result => {
            refresh()
            setLoadingBackdrop(false)
            setAskBillModal(false)
        }, null, () => {
            setLoadingBackdrop(false)
            setAskBillModal(false)
        })
    }

    const refresh = () => {
        load()
    }

    const load = () => {
        setLoadingBackdrop(true)
        props.request("cart/get", null, result => {
            setData(result.data)
            setLoadingBackdrop(false)
        })
    }

    React.useEffect(() => {
        if (props.cart) {
            load()
        }
    }, [props.cart])

    React.useEffect(() => {
        let myState = null

        if (data.articles) {
            data.articles.forEach(article => {
                if (data.bill && myState !== CART_STATE.TO_VALIDATE) {
                    myState = CART_STATE.BILL
                }
                if (article.state_orders_lines === "0") {
                    myState = CART_STATE.TO_VALIDATE
                }
                if (!myState && article.state_orders_lines === "1") {
                    myState = CART_STATE.WAITING_ESTABLISHMENT
                }
                if (!myState && article.state_orders_lines === "2") {
                    myState = CART_STATE.VALIDATED
                }
                if (article.state_orders_lines === "3" && myState !== CART_STATE.TO_VALIDATE && myState !== CART_STATE.BILL && myState !== CART_STATE.WAITING_ESTABLISHMENT && myState !== CART_STATE.VALIDATED) {
                    myState = CART_STATE.PAID
                }

            })
        }
        setState(myState)
    }, [data])


    const container = window !== undefined ? () => window().document.body : undefined

    let price;

    if (data.articles) {
        price = data.articles.reduce((prev, next) => {
            if (next.state_orders_lines === "3")
                return prev + 0;

            return prev + (parseFloat(next.final_price) * parseInt(next.quantity, 10))
        }, 0)
    }

    return <>
        <Root>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(500px - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />

            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={props.cart}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                disableDiscovery={false}
                ModalProps={{
                    keepMounted: location.pathname === "/",
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,

                    }}
                    style={{
                        boxShadow: "0px 4px 2px #999, 0px -2px 5px #999"
                    }}
                >
                    <Puller/>
                    <h3 className={"tc cartTitle"} style={{
                        marginTop: "30px",
                        backgroundColor: "rgb(245, 245, 245)",
                        marginBottom: "1vh"
                    }}>{i18next.t('global.cart')}</h3>

                    {!loadingBackdrop && state === CART_STATE.WAITING_ESTABLISHMENT &&
                    <div className={"nbCart"}>{i18next.t('global.soonValidate')} ⌛</div>}
                    {!loadingBackdrop && state === CART_STATE.TO_VALIDATE &&
                    <div className={"nbCart"}>{i18next.t('global.toValidate')} ⚠</div>}
                    {!loadingBackdrop && state === CART_STATE.VALIDATED &&
                    <div className={"nbCart"}>{i18next.t('global.validatedCart')} {"🧑‍🍳"/*kitchen*/}</div>}
                    {!loadingBackdrop && state === CART_STATE.BILL &&
                    <div className={"nbCart"}>{i18next.t('global.bill')} {"💸"/*moneyLogo*/}</div>}

                    <div className={"cartContainer"} style={{
                        padding: "1em",
                        height: !state ? "369.4px" : "332.15px",
                        overflowY: "scroll",
                        paddingBottom: "0"
                    }}>
                        {
                            loadingBackdrop && (
                                <ColoredLinearProgress style={{width: "100%"}}/>
                            )
                        }

                        {
                            !loadingBackdrop && data.articles && data.articles.map((article) => {
                                if (!lastStateArticle || lastStateArticle !== article.state_orders_lines) {
                                    lastStateArticle = article.state_orders_lines;
                                    return <React.Fragment key={article.id_orders_lines}>
                                        <div className={"title"}>
                                            {article.state_orders_lines === "0" && i18next.t(`global.waitingValidation`)}
                                            {article.state_orders_lines === "1" && i18next.t(`global.notValidated`)}
                                            {article.state_orders_lines === "2" && i18next.t(`global.validated`)}
                                            {article.state_orders_lines === "3" && i18next.t(`global.paid`)}
                                        </div>
                                        <ArticleCart refresh={refresh} key={article.id_orders_lines} article={article}/>
                                    </React.Fragment>
                                }

                                return <ArticleCart refresh={refresh} key={article.id_orders_lines} article={article}/>

                            })
                        }
                    </div>
                    {
                        state === CART_STATE.TO_VALIDATE && (
                            <Link onClick={() => validateCart()} underline={"none"}>
                                <div className={"cartButton"} style={{background: "#07bc0c"}}>
                                    <div
                                        className={"cartButtonText animateText"}>{i18next.t('global.validateCart')} - {price}{props.currency}</div>
                                </div>
                            </Link>
                        )
                    }

                    {
                        (!state || state === CART_STATE.PAID || state === CART_STATE.WAITING_ESTABLISHMENT || state === CART_STATE.BILL) && price > 0 && (
                            <div className={"cartButton"}>
                                <div
                                    className={"cartButtonText"}>{i18next.t('global.totalCart')} - {price}{props.currency}</div>
                            </div>
                        )
                    }

                    {
                        (state === CART_STATE.VALIDATED) && price > 0 && (
                            <Link onClick={() => setAskBillModal(true)} underline={"none"}>
                                <div className={"cartButton"} style={{background: "#07bc0c"}}>
                                    <div
                                        className={"cartButtonText"}>{i18next.t('global.askBill')} - {price}{props.currency}</div>
                                </div>
                            </Link>
                        )
                    }

                </StyledBox>
            </SwipeableDrawer>
        </Root>

        <Dialog onClose={() => setAskBillModal(false)} open={askBillModal}>
            <DialogTitle>{i18next.t('global.askBillWay')}</DialogTitle>
            <List sx={{pt: 0}}>
                <ListItem autoFocus button onClick={() => askBill(1)}>
                    <ListItemAvatar>
                        <Avatar style={{background: "rgb(246, 188, 65)"}}>
                            {props.currency}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={i18next.t('global.cash')}/>
                </ListItem>
                <ListItem autoFocus button onClick={() => askBill(0)}>
                    <ListItemAvatar>
                        <Avatar style={{background: "rgb(246, 188, 65)"}}>
                            <CreditCardIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={i18next.t('global.bc')}/>
                </ListItem>
                <ListItem autoFocus button onClick={() => askBill(2)}>
                    <ListItemAvatar>
                        <Avatar style={{background: "rgb(246, 188, 65)"}}>
                            <CreditCardIcon style={{fontSize: "0.7em"}}/><span
                            style={{fontSize: "0.7em"}}>/{props.currency}</span>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={i18next.t('global.cbAndCash')}/>
                </ListItem>
            </List>
        </Dialog>

        {
            announcement && (
                <Link onClick={() => {
                    if(closableAnnnouncement <= 0)
                        setAnnouncement(null)
                }}>
                    <div style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        height: "100%",
                        width: "100%",
                        zIndex: 10000,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "black",
                        backgroundImage: `url("${AdsLink}${announcement}")`
                    }}>
                        <div className={"closableAnnouncement"}>
                            {closableAnnnouncement > 0 ? closableAnnnouncement : (
                                <IconButton><CloseIcon style={{color: "white", fontSize: "1.2em"}} /></IconButton>
                            )}
                        </div>
                    </div>
                </Link>
            )
        }

    </>
}

const mapStateToProps = (state) => ({
    cart: state.global.cart,
    currency: state.global.currency,
    request: state.request.request,
});

export default connect(mapStateToProps)(Cart)