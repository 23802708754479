import {SET_SOS, SET_SOS_CHOICE} from "../actions/sosActions";

const initialState = {
    sos: false,
    choice: null
};

export default function sosReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SOS : {
            return {
                ...state,
                sos: action.value
            };
        }
        case SET_SOS_CHOICE : {
            return {
                ...state,
                choice: action.value
            };
        }
        default:
            return state
    }
}
