import {connect} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import "../../style/menu.css"
import AppBar from "./shared/AppBar";
import Grid from "@mui/material/Grid";
import i18next from "i18next";
import Article from "./shared/Article";
import {useParams} from "react-router-dom";
import {resetSession, setError} from "../../redux/actions/globalActions";
import ColoredLinearProgress from "./shared/ColoredLinearProgress";
import {timestampTimeout} from "../helper/global";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

const Menu = props => {

    const [articles, setArticles] = useState([])
    const [search, setSearch] = useState([])

    useEffect(() => {
        setTimeout(() => {
            setReady(true)
        }, 100)
    }, []);

    React.useEffect(() => {
        if (props.timestamp) {
            const date = new Date()
            const timestamp = (date.getTime() / 1000).toFixed();
            if (props.timestamp && timestamp - props.timestamp > timestampTimeout) {
                setError(i18next.t('error.scanQR'))
                resetSession()
            } else if (!props.timestamp) {
                setError(i18next.t('error.scanQR'))
                resetSession()
            }
        }
    }, [props.timestamp])

    React.useEffect(() => {
        if (props.menu && props.menu[category] && props.menu[category].categories) {
            let art = []
            Object.keys(props.menu[category].categories).forEach(key => {
                Object.keys(props.menu[category].categories[key].articles).forEach(keyArt => {
                    let article = props.menu[category].categories[key].articles[keyArt];
                    article.category = key;
                    art.push(article)
                })
            })
            setArticles(art)
        }
    }, [props.menu])

    const {category} = useParams();
    const [ready, setReady] = useState(false);

    if (!props.menu) {
        return ""
    }


    return <>
        <AppBar category={category}/>

        <Autocomplete
            multiple
            limitTags={2}
            getOptionLabel={(option) => option.name_article}
            className={"searchField"}
            onChange={(event, newValue) => {
                setSearch(newValue);
            }}
            options={articles}
            groupBy={(option) => option.category}
            renderGroup={({key, group, children}) => <Fragment key={key}>
                <Typography>{i18next.t(`categories.${group}`)}</Typography><>{children}</>
            </Fragment>}
            renderInput={(params) => (
                <TextField className={"searchFieldInput"}
                           placeholder={`${i18next.t('global.search')} ${"🔎"}`} {...params} />
            )}
        />

        <div className={"menuContainer"}>
            <Zoom in={search && search.length > 0} unmountOnExit>
                <div>
                    <div className={"title"}>
                        {i18next.t(`global.searchResults`)}
                    </div>
                    <Grid container justifyContent={"center"} spacing={2}>
                        {
                            search.map(article => {
                                return <Article key={article.id_article} article={article}/>
                            })
                        }
                    </Grid>
                </div>
            </Zoom>

            {
                ready ?
                    props.menu && props.menu[category] && props.menu[category].categories ? Object.keys(props.menu[category].categories).map(key => (
                        <React.Fragment key={key}>
                            <div className={"title"} name={key} id={key}>
                                {i18next.t(`categories.${key}`)}
                            </div>

                            <Grid container justifyContent={"center"} spacing={2}>
                                {
                                    props.menu[category].categories && Object.keys(props.menu[category].categories[key].articles).map(ka => {
                                        const article = props.menu[category].categories[key].articles[ka];
                                        return <Article key={article.id_article} article={article}/>
                                    })
                                }
                            </Grid>

                        </React.Fragment>
                    )) : (
                        <div className={"title"}>
                            {i18next.t(`global.noProduct`)}
                        </div>
                    ) : <ColoredLinearProgress style={{marginTop: "5vh"}}/>
            }

        </div>
    </>
}

const mapStateToProps = (state) => ({
    menu: state.global.menu,
    timestamp: state.global.timestamp,
    request: state.request.request
});

export default connect(mapStateToProps)(Menu)