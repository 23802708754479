import React from "react";
import {connect} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {apiLink} from "../helper/global";
import {
    resetSession,
    setCurrency,
    setError,
    setIdEstablishment,
    setIdTable,
    setLoading,
    setLogoEstablishment,
    setNameEstablishment,
    setNameTable,
    setTimestamp,
    setToken
} from "../../redux/actions/globalActions";
import {toast} from "react-toastify";
import i18next from "i18next";
import {setColors} from "../../redux/actions/colorsActions";

const Init = props => {

    let params = useParams();
    let navigate = useNavigate();

    const checkToken = () => {
        setLoading(i18next.t('global.checking'))
        const formData = new FormData()
        formData.append("token", params.token)

        fetch(`${apiLink}Auth/checkToken`, {
            method: "POST",
            body: formData
        }).then(r => r.json()).then(r => {

            if (r.message) {
                toast.error(i18next.t(r.message))
                setError(i18next.t(r.message))
                setLoading(null)
                return;
            }

            setLoading(i18next.t('global.init'))

            setToken(r.data.token);
            setIdEstablishment(r.data.id_establishment);
            setIdTable(r.data.id_table);
            setNameEstablishment(r.data.name_establishment)
            setLogoEstablishment(r.data.logo_establishment)
            setNameTable(r.data.name_establishment)
            setTimestamp(r.data.timestamp)
            setCurrency(r.data.currency_establishment)
            setColors(r.data.colors)
        })
    }

    React.useEffect(() => {
        resetSession()
        checkToken()
    }, [])

    React.useEffect(() => {
        if (props.token && props.id_establishment && props.id_table) {
            navigate("/")
        }
    }, [props.token, props.id_establishment, props.id_table])

    return <></>
}

const mapStateToProps = (state) => ({
    id_table: state.global.id_table,
    id_establishment: state.global.id_establishment,
    token: state.global.token,
    name_establishment: state.global.name_establishment,
});

export default connect(mapStateToProps)(Init)