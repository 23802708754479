import {connect} from "react-redux";
import React, {useEffect} from "react";
import "../../../style/appbar.css"
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {setCart} from "../../../redux/actions/globalActions";
import Box from "@mui/material/Box";
import cartImage from "../../../assets/images/cart_yellow.png";
import {Link as Scroll} from "react-scroll";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";

const AppBar = props => {

    const navigate = useNavigate()

    useEffect(() => {
            const focusCategory = () => {
                const offset = 80;
                const position = window.pageYOffset + offset;
                const titles = document.getElementsByClassName("title")
                let prev = -1;

                for (let i = 0; i < titles.length; i++) {
                    if (document.getElementsByClassName("topBarCategoryValue")[i]) {

                        const currentPosition = (titles[i].getBoundingClientRect().top + window.pageYOffset);
                        if (titles.length === 1) {
                            document.getElementsByClassName("topBarCategoryValue")[i].className = 'topBarCategoryValue selected'
                        } else if (titles[i + 1]) {
                            const nextPosition = (titles[i + 1].getBoundingClientRect().top + window.pageYOffset);
                            if (prev === -1 && position < nextPosition) {
                                document.getElementsByClassName("topBarCategoryValue")[i].className = 'topBarCategoryValue selected';
                                document.getElementsByClassName("topBarCategoryValue")[i].scrollIntoView({behavior: "smooth"});
                            } else if (position >= prev && position < nextPosition && position >= currentPosition) {
                                document.getElementsByClassName("topBarCategoryValue")[i].className = 'topBarCategoryValue selected';
                                document.getElementsByClassName("topBarCategoryValue")[i].scrollIntoView({behavior: "smooth"});
                            } else {
                                document.getElementsByClassName("topBarCategoryValue")[i].className = 'topBarCategoryValue';
                            }

                            prev = currentPosition;
                        } else {
                            document.getElementsByClassName("topBarCategoryValue")[i].className = 'topBarCategoryValue';
                        }
                    }
                }

                if (document.getElementsByClassName("topBarCategoryValue selected")) {
                    if (document.getElementsByClassName("topBarCategoryValue selected").length === 0) {
                        const categories = document.getElementsByClassName("topBarCategoryValue");
                        if (categories[categories.length - 1]) {
                            categories[categories.length - 1].className = 'topBarCategoryValue selected';
                            if (document.getElementsByClassName("topBarCategoryValue")[categories.length - 1])
                                document.getElementsByClassName("topBarCategoryValue")[categories.length - 1].scrollIntoView({behavior: "smooth"});
                        }
                    }
                }
            }

            if (document.getElementsByClassName("topBarCategoryValue")[0])
                document.getElementsByClassName("topBarCategoryValue")[0].className = 'topBarCategoryValue selected'

            document.addEventListener('scroll', () => {
                focusCategory()
            });

            return () => {
                document.removeEventListener('scroll', () => {
                    focusCategory()
                });
            };

            /* asynch method

            const createScrollStopListener = (element, callback, timeout) => {
                let removed = false;
                let handle = null;
                const onScroll = () => {
                    if (handle) {
                        clearTimeout(handle);
                    }
                    handle = setTimeout(callback, timeout || 200); // default 200 ms
                };
                element.addEventListener('scroll', onScroll);
                return () => {
                    if (removed) {
                        return;
                    }
                    removed = true;
                    if (handle) {
                        clearTimeout(handle);
                    }
                    element.removeEventListener('scroll', onScroll)
                };
            };

            const destroyListener = createScrollStopListener(window, () => {
                focusCategory()
            });
            return () => destroyListener();
            */

        }, []
    )
    ;

    return <>
        <Paper className={"navBarContainer"} elevation={5}>
            <div className={"leftActionButton"}><IconButton onClick={() => navigate("/")}><ArrowBackIosNewIcon
                style={{color: "#0240aa"}}/></IconButton></div>

            <div className={"cartAppBar"}>
                <IconButton onClick={() => setCart(true)}>
                    <img src={cartImage} width={27} height={36} alt={"Cart"}/>
                </IconButton>
            </div>

            <Box className={"topBarCategories"} display="flex" alignItems="center">
                {
                    props.global.menu[props.category].categories ? Object.keys(props.global.menu[props.category].categories).map(key => (
                        <Box className={"topBarCategory"} key={key}>
                            <Scroll to={`${key}`} spy={true} smooth={true} offset={-70} duration={500}>
                                <div className={"topBarCategoryValue"}>{i18next.t(`categories.${key}`)}</div>
                            </Scroll>
                        </Box>
                    )) : (
                        <Box className={"topBarCategory"}>
                            <div className={"topBarCategoryValue selected"}>{i18next.t(`categories.noCategory`)}</div>
                        </Box>
                    )
                }

            </Box>

        </Paper>
    </>
}
const mapStateToProps = (state) => ({
    global: state.global,
    request: state.request.request
});

export default connect(mapStateToProps)(AppBar)