import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import softsImage from '../../assets/images/softs.png'
import othersImage from '../../assets/images/others.png'
import alcoolsImage from '../../assets/images/alcools.png'
import chichasImage from '../../assets/images/chichas.png'
import sosImage from '../../assets/images/sos.png'
import foodImage from '../../assets/images/food.svg'
import cafetaria from '../../assets/images/coffee_5.png'
import i18next from "i18next";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import {setCart, setLanguage} from "../../redux/actions/globalActions";
import {LogoLink} from "../helper/global";
import cartImage from "./../../assets/images/cart_yellow.png";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import ar from "./../../assets/images/flags/ar.png"
import gb from "./../../assets/images/flags/gb.png"
import es from "./../../assets/images/flags/es.png"
import fr from "./../../assets/images/flags/fr.png"
import cn from "./../../assets/images/flags/cn.png"
import de from "./../../assets/images/flags/de.png"
import he from "./../../assets/images/flags/he.png"
import it from "./../../assets/images/flags/it.png"
import jp from "./../../assets/images/flags/jp.png"
import pt from "./../../assets/images/flags/pt.png"
import ru from "./../../assets/images/flags/ru.png"

import Sos from "./shared/Sos";
import {setSos} from "../../redux/actions/sosActions";

const flag = [];
flag['ar'] = ar
flag['gb'] = gb
flag['es'] = es
flag['fr'] = fr
flag['cn'] = cn
flag['de'] = de
flag['he'] = he
flag['it'] = it
flag['jp'] = jp
flag['pt'] = pt
flag['ru'] = ru

const Categories = props => {

    const [type, setType] = useState(null)
    const navigation = useNavigate()

    useEffect(() => {
        const alcools = Object.keys(props.menu.ALCOOLS).length;
        const foods = Object.keys(props.menu.FOODS).length;
        const chichas = Object.keys(props.menu.Chichas).length;
        if (alcools && foods && chichas) {
            setType("ACF")
        } else if (alcools && chichas) {
            setType("AC")
        } else if (alcools && foods) {
            setType("AF")
        } else if (foods && chichas) {
            setType("FC")
        } else if (chichas) {
            setType("C")
        } else if (alcools) {
            setType("A")
        }

    }, props.menu)

    const languages = [
        {code: "fr", label: "Français"},
        {code: "gb", label: "English"},
        {code: "ar", label: "عرب"},
        {code: "es", label: "Español"},
        {code: "cn", label: "中国人"},
        {code: "de", label: "Deutsch"},
        {code: "he", label: "עברים"},
        {code: "it", label: "Italiano"},
        {code: "jp", label: "日本"},
        {code: "pt", label: "Português"},
        {code: "ru", label: "Русский"},
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = (value) => {
        setLanguage(value)
        handleClose()
    }

    return <>

        {!props.error &&
        <div
            className={(props.language === "ar" || props.language === "he") ? "languageSelectorAr" : "languageSelectorN"}>
            <IconButton
                className={"languageSelectorButton"}
                aria-controls={open ? 'fade-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Avatar><img style={{width: "100%", height: "100%"}} alt="Current language flag"
                             src={flag[props.language]}/></Avatar>
            </IconButton>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {
                    languages.map((language) => (
                        <MenuItem key={language.label} onClick={() => changeLanguage(language.code)}
                                  value={language.code}><Avatar style={{marginRight: "8px"}}
                                                                sx={{width: 24, height: 24}}><img
                            style={{width: "100%", height: "100%"}} alt={`${language.label} flag`}
                            src={flag[language.code]}/></Avatar> {language.label}</MenuItem>
                    ))
                }
            </Menu>
        </div>
        }

        {
            !props.error &&
            <div className={(props.language === "ar" || props.language === "he") ? "cartAr" : "cart"}>
                <IconButton onClick={() => setCart(true)}>
                    <img src={cartImage} width={27} height={36} alt={"Cart"}/>
                </IconButton>
            </div>
        }

        <img className={"logoHeader"} src={`${LogoLink}${props.logo_establishment}`} alt={"Logo"}/>

        <Link onClick={() => {
            navigation("/menu/SOFTS")
        }}>
            <div className={"categoryButton"} style={{
                height: Object.keys(props.menu.Cafetaria).length === 0 ? "22%" : "15%",
                top: "12%",
                right: "5%",
                backgroundImage: `url('${softsImage}')`,
                backgroundColor: "#E9806E"
            }}>
                <div className={"legend top"}>
                    {i18next.t('global.softs')}
                </div>
            </div>
        </Link>

        <Link onClick={() => {
            navigation("/menu/OTHERS")
        }}>
            <div className={"categoryButton"} style={{
                height: Object.keys(props.menu.Cafetaria).length === 0 ? "22%" : "15%",
                top: Object.keys(props.menu.Cafetaria).length === 0 ? "37%" : "45%",
                right: "5%",
                backgroundImage: `url('${othersImage}')`,
                backgroundColor: "#92CED7"
            }}>
                <div className={"legend bottom"}>
                    {i18next.t('global.others')}
                </div>
            </div>
        </Link>

        {
            Object.keys(props.menu.Cafetaria).length > 0 && (
                <Link onClick={() => {
                    navigation("/menu/Cafetaria")
                }}>
                    <div className={"categoryButton"} style={{
                        height: "15%",
                        top: "28.5%",
                        right: "5%",
                        backgroundImage: `url('${cafetaria}')`,
                        backgroundColor: "#B08FCE"
                    }}>
                        <div className={"legendRight"}>
                            Chaud
                        </div>
                    </div>
                </Link>
            )
        }

        {
            type === "AC" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/ALCOOLS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "50%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${alcoolsImage}')`,
                            backgroundColor: "#102130"
                        }}>
                            <div className={"legendLeft"}>
                                {i18next.t('global.alcools')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        navigation("/menu/Chichas")
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% -7px",
                            height: "22%",
                            top: "65%",
                            right: "5%",
                            backgroundImage: `url('${chichasImage}')`,
                            backgroundColor: "#d9c5a6"
                        }}>
                            <div className={"legend bottom"}>
                                {i18next.t('global.chichas')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => setSos(true)}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "22%",
                            top: "65%",
                            left: "5%",
                            backgroundImage: `url('${sosImage}')`,
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }
        {
            type === "AF" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/ALCOOLS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "50%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${alcoolsImage}')`,
                            backgroundColor: "#102130"
                        }}>
                            <div className={"legendLeft"}>
                                {i18next.t('global.alcools')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        navigation("/menu/FOODS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "22%",
                            top: "65%",
                            left: "5%",
                            backgroundImage: `url('${foodImage}')`,
                            backgroundColor: "rgb(255, 232, 212)"
                        }}>
                            <div className={"legend bottom"}>
                                {i18next.t('global.food')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        setSos(true)
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "22%",
                            top: "65%",
                            right: "5%",
                            backgroundImage: `url('${sosImage}')`,
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }
        {
            type === "A" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/ALCOOLS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "50%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${alcoolsImage}')`,
                            backgroundColor: "#102130"
                        }}>
                            <div className={"legendLeft"}>
                                {i18next.t('global.alcools')}
                            </div>
                        </div>
                    </Link>
                    <Link onClick={() => setSos(true)}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "20%",
                            top: "65%",
                            left: "50%",
                            width: "90%",
                            backgroundImage: `url('${sosImage}')`,
                            transform: "translateX(-50%)",
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }
        {
            type === "C" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/Chichas")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "50%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${chichasImage}')`,
                            backgroundColor: "#EBB3A9"
                        }}>
                            <div className={"legendLeft"}>
                                {i18next.t('global.chichas')}
                            </div>
                        </div>
                    </Link>
                    <Link onClick={() => {
                        setSos(true)
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "20%",
                            top: "65%",
                            left: "50%",
                            width: "90%",
                            backgroundImage: `url('${sosImage}')`,
                            transform: "translateX(-50%)",
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }

        {
            type === "FC" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/Chichas")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "50%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${chichasImage}')`,
                            backgroundColor: "#a09f9f"
                        }}>
                            <div className={"legendLeft"}>
                                {i18next.t('global.chichas')}
                            </div>
                        </div>
                    </Link>
                    <Link onClick={() => {
                        setSos(true)
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "22%",
                            top: "65%",
                            right: "5%",
                            backgroundImage: `url('${sosImage}')`,
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        navigation("/menu/FOODS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "22%",
                            top: "65%",
                            left: "5%",
                            backgroundImage: `url('${foodImage}')`,
                            backgroundColor: "rgb(255, 232, 212)"
                        }}>
                            <div className={"legend bottom"}>
                                {i18next.t('global.food')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }

        {
            type === "ACF" && (
                <>
                    <Link onClick={() => {
                        navigation("/menu/ALCOOLS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "22%",
                            top: "12%",
                            left: "5%",
                            backgroundImage: `url('${alcoolsImage}')`,
                            backgroundColor: "#102130"
                        }}>
                            <div className={"legend top"}>
                                {i18next.t('global.alcools')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        navigation("/menu/Chichas")
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% -7px",
                            height: "22%",
                            top: "65%",
                            right: "5%",
                            backgroundImage: `url('${chichasImage}')`,
                            backgroundColor: "#d9c5a6"
                        }}>
                            <div className={"legend bottom"}>
                                {i18next.t('global.chichas')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        navigation("/menu/FOODS")
                    }}>
                        <div className={"categoryButton"} style={{
                            height: "22%",
                            top: "37%",
                            left: "5%",
                            backgroundImage: `url('${foodImage}')`,
                            backgroundColor: "rgb(255, 232, 212)"
                        }}>
                            <div className={"legend bottom"}>
                                {i18next.t('global.food')}
                            </div>
                        </div>
                    </Link>

                    <Link onClick={() => {
                        setSos(true)
                    }}>
                        <div className={"categoryButton"} style={{
                            backgroundPosition: "50% 25px",
                            height: "22%",
                            top: "65%",
                            left: "5%",
                            backgroundImage: `url('${sosImage}')`,
                            backgroundColor: "#F8C0C8"
                        }}>
                            <div className={"legend bottom"} style={{background: "#AA8AD7"}}>
                                {i18next.t('global.sos')}
                            </div>
                        </div>
                    </Link>
                </>
            )
        }

        <Sos/>
    </>
}

const mapStateToProps = (state) => ({
    language: state.global.language,
    error: state.global.error,
    menu: state.global.menu,
    logo_establishment: state.global.logo_establishment
});

export default connect(mapStateToProps)(Categories)