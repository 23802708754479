let BackEnd ;

if (process.env.NODE_ENV === 'development') {
    BackEnd = "https://dev.api.evenights.com/api/qr/";
} else {
    BackEnd = "https://api.evenights.com/api/qr/";
}

export const apiLink = BackEnd ;

export const AssetsLink = "https://files.evenights.com/assets/img/establishment/articles/";
export const LogoLink = "https://files.evenights.com/assets/img/establishment/logo/";
export const AdsLink = "https://files.evenights.com/assets/ad/";

export const MOTHER_CATEGORIES = {};

MOTHER_CATEGORIES.ALCOOLS = "ALCOOLS"
MOTHER_CATEGORIES.SOFTS = "SOFTS"
MOTHER_CATEGORIES.SOFT_GLASS = "SOFT_GLASS"
MOTHER_CATEGORIES.OTHERS = "OTHERS"
MOTHER_CATEGORIES.FOODS = "FOODS"
MOTHER_CATEGORIES.CHOICES = "CHOICES"
MOTHER_CATEGORIES.CHICHAS = "Chichas"

export const MENU_CHOICE = {};

MENU_CHOICE.VODKA = "VODKA";
MENU_CHOICE.WHISKY = "WHISKY";
MENU_CHOICE.GIN = "GIN";
MENU_CHOICE.RHUM = "RHUM";
MENU_CHOICE.TEQUILA = "TEQUILA";
MENU_CHOICE.WINE_RED = "WINE_RED";
MENU_CHOICE.WINE_ROSE = "WINE_ROSÉ";
MENU_CHOICE.WINE_WHITE = "WINE_WHITE";
MENU_CHOICE.BEER = "BEER";
MENU_CHOICE.COCKTAIL = "COCKTAIL";
MENU_CHOICE.LIQUOR = "LIQUOR";
MENU_CHOICE.SHOT = "SHOT";
MENU_CHOICE.CHAMPAGNE = "CHAMPAGNE";
MENU_CHOICE.PACK = "PACK";
MENU_CHOICE.SOFT_GLASS = "SOFT_GLASS";
MENU_CHOICE.GLASS = "GLASS";
MENU_CHOICE.OTHER_BOTTLES = "OTHER_BOTTLES";

MENU_CHOICE.ALCOOLS = [
    MENU_CHOICE.VODKA,
    MENU_CHOICE.WHISKY,
    MENU_CHOICE.PACK,
    MENU_CHOICE.GIN,
    MENU_CHOICE.RHUM,
    MENU_CHOICE.TEQUILA,
    MENU_CHOICE.WINE_RED,
    MENU_CHOICE.WINE_ROSE,
    MENU_CHOICE.WINE_WHITE,
    MENU_CHOICE.BEER,
    MENU_CHOICE.COCKTAIL,
    MENU_CHOICE.LIQUOR,
    MENU_CHOICE.SHOT,
    MENU_CHOICE.CHAMPAGNE,
    MENU_CHOICE.GLASS
];


MENU_CHOICE.SODA = "Soda";
MENU_CHOICE.JUICE = "Juice";
MENU_CHOICE.WATER = "Water";

MENU_CHOICE.SOFTS = [
    MENU_CHOICE.SODA,
    MENU_CHOICE.JUICE,
    MENU_CHOICE.WATER
]


MENU_CHOICE.SWEETS = "Sweets";
MENU_CHOICE.CIGARETTES = "Cigarettes";
MENU_CHOICE.BALLOONS = "Balloons";
MENU_CHOICE.CHICHAS = "Chichas";

MENU_CHOICE["FOODS"] = [
    MENU_CHOICE.FOOD = "FOOD",
    MENU_CHOICE.STARTER = "STARTER",
    MENU_CHOICE.MAIN_FOOD = "MAIN_FOOD",
    MENU_CHOICE.DESERT = "DESERT",
    MENU_CHOICE.MEATS = "MEATS",
    MENU_CHOICE.MEATS = "FISHES",
    MENU_CHOICE.MEATS = "SALADS",
    MENU_CHOICE.MEATS = "SHARES",
    MENU_CHOICE.MEATS = "MENUS",
];

MENU_CHOICE["OTHERS"] = [
    MENU_CHOICE.SWEETS,
    MENU_CHOICE.CIGARETTES,
    MENU_CHOICE.BALLOONS
]

MENU_CHOICE["CHICHAS"] = [MENU_CHOICE.CHICHAS]


MENU_CHOICE.SOFT = "soft";
MENU_CHOICE.SOFT_GLASS = "SOFT_GLASS";

export const timestampTimeout = 60000 ;

export const CART_STATE = {};
CART_STATE.WAITING_ESTABLISHMENT = "WAITING_ESTABLISHMENT"
CART_STATE.TO_VALIDATE = "TO_VALIDATE"
CART_STATE.VALIDATED = "VALIDATE"
CART_STATE.PAID = "PAID"
CART_STATE.BILL = "BILL"

export const defaultColors = {
    backgroundColor: "#fff",
    wave1: "#3668b8",
    wave2: "#2A5292",
    wave3: "#24457A",
    buttonsColor: "#5456D3",
    fontAndCategoryColor: '#134c9a',
    buttonOrderColor: '#fec433',
    sosButtonColor: '#aa8ad7',
    chatAndSelfieButtonColor: '#abbcec',
    sosBackgroundColor: '#F8C0C8',
    chatAndSelfieBackgroundColor: '#F5F9E9',
    hardBackgroundColor: '#102130',
    softBackgroundColor: '#E9806E',
    otherBackgroundColor: '#92CED7',
    foodBackgroundColor: '#FFE8D4',
    billBackgroundColor: '#FFFFFF',
    buttonsTextColor: '#FFFFFF',
    sosTextColor: '#FFFFFF',
    chatAndSelfieTextColor: '#FFFFFF',
}
