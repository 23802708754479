import {connect} from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import i18next from "i18next";
import {AssetsLink} from "../../helper/global";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from "@mui/material/Backdrop";
import ColoredLinearProgress from "./ColoredLinearProgress";
import DeleteIcon from "@mui/icons-material/Delete"

const ArticleCart = (props) => {

    const {article, key} = props;
    const [quantity, setQuantity] = useState(parseInt(article.quantity, 10))
    const [loadingBackdrop, setLoadingBackdrop] = useState(false)

    const changeQuantity = (newQuantity) => {
        setLoadingBackdrop(true)
        props.request("/cart/set_quantity", {
            quantity: newQuantity,
            id_orders_lines: article.id_orders_lines
        }, result => {
            setQuantity(newQuantity)
            props.refresh()
            setLoadingBackdrop(false)
        })
    }

    return <React.Fragment key={key}>

        <Backdrop
            style={{zIndex: 10}}
            open={loadingBackdrop}
        ><ColoredLinearProgress style={{width: "100%"}}/></Backdrop>

        <Grid item xs={12} style={{marginBottom: "1em"}}>
            <Paper elevation={1} className={"paperArticle"}>

                {
                    article.state_orders_lines === "0" && (
                        <div className={"deleteButton"}>
                            <IconButton onClick={() => changeQuantity(0)}>
                                <DeleteIcon style={{color: "red"}}/>
                            </IconButton>
                        </div>
                    )
                }


                <Grid container alignItems={"center"}>
                    <Grid item xs={8} style={{position: "relative"}}>
                        <Grid container style={{minHeight: "120px"}} direction="column" justifyContent="space-around"
                              alignItems="stretch">
                            <Grid item className={"nameArticle"}>
                                {article.name_article}
                                {article.state_orders_lines === "1" && " ⌛"}
                                {article.state_orders_lines === "2" && " ✅"}
                                {article.state_orders_lines === "3" && " 💰"}
                            </Grid>
                            {article.desc_article.length > 0 ? (
                                <Grid item className={"descArticle"}>
                                    {
                                        article.softs.length > 0 ? (
                                            article.softs.map(soft => soft.name_article).join(",")
                                        ) : (
                                            article.desc_article
                                        )
                                    }
                                </Grid>
                            ) : (
                                <Grid item className={"descArticle"}>
                                    {
                                        article.softs.length > 0 ? (
                                            article.softs.map(soft => soft.name_article).join(",")
                                        ) : (
                                            i18next.t('global.noDesc')
                                        )
                                    }
                                </Grid>
                            )}

                            <Grid item className={"priceArticle"}>
                                <Grid container direction="row"
                                      justifyContent="space-around"
                                      alignItems="center">
                                    <Grid item>
                                        {(parseFloat(article.final_price) * quantity).toFixed(2)}{props.currency}
                                    </Grid>
                                    {
                                        article.state_orders_lines === "0" && (
                                            <Grid item>
                                                <Grid container direction="row"
                                                      justifyContent="center"
                                                      alignItems="center"
                                                      spacing={1}>
                                                    <Grid item><IconButton onClick={() => changeQuantity(quantity - 1)}
                                                                           className={"quantityButton"}><HorizontalRuleIcon
                                                        className={"quantityIcon"}/></IconButton></Grid>
                                                    <Grid item><span className={"quantity"}>{quantity}</span></Grid>
                                                    <Grid item><IconButton onClick={() => changeQuantity(quantity + 1)}
                                                                           className={"quantityButton"}><AddIcon
                                                        className={"quantityIcon"}/></IconButton></Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    {
                                        article.state_orders_lines !== "0" && (
                                            <Grid item>
                                                <Grid container direction="row"
                                                      justifyContent="center"
                                                      alignItems="center"
                                                      spacing={1}>
                                                    <Grid item><span className={"quantity"}>x{quantity}</span></Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={"pictureArticleContainer"} style={{
                            backgroundImage: `url("${AssetsLink}${article.article_img}")`,
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat"
                        }}/>
                    </Grid>
                </Grid>

            </Paper>
        </Grid>
    </React.Fragment>
}

const mapStateToProps = (state) => ({
    menu: state.global.menu,
    currency: state.global.currency,
    request: state.request.request
});

export default connect(mapStateToProps)(ArticleCart)