import {SET_COLORS} from "../actions/colorsActions";
import {defaultColors} from "../../app/helper/global";

let colors = localStorage.getItem("colors");
if(colors === null || colors === "null") {
    colors = defaultColors
}   else
    colors = JSON.parse(colors) ;

const initialState = {
    colors
}

export default function colorsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_COLORS : {
            return {
                ...state,
                colors: action.value
            };
        }
        default:
            return state
    }
}
