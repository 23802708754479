import {connect} from "react-redux";
import React, {useEffect} from "react";
import "../../style/template.css"
import { Outlet } from "react-router-dom";
import i18next from "i18next";
import {setRequest} from "../../redux/actions/requestActions";
import {setError, setLoading, setMenu} from "../../redux/actions/globalActions";
import {apiLink} from "./global";
import {toast} from "react-toastify";

const Middleware = props => {

    useEffect(() => {
        if (props.token && props.id_table && props.id_establishment) {
            if (props.request === null) {
                setRequest((url = null, data = null, callBack = () => {
                }, loadingMessage = null, errorCallback = null) => {
                    if (loadingMessage)
                        setLoading(loadingMessage)
                    if (url === null)
                        return "init";
                    const formData = new FormData();
                    formData.append('token', props.token)
                    formData.append('id_establishment', props.id_establishment)
                    formData.append('id_table', props.id_table)
                    if (data)
                        Object.keys(data).forEach(key => {
                            const value = data[key]
                            formData.append(key, value)
                        })
                    fetch(`${apiLink}${url}`, {method: "POST", body: formData}).then(r => r.json()).then(r => {
                        if (r.error === 0)
                            callBack(r)
                        else {
                            setError(i18next.t(r.message))
                            toast.error(i18next.t(r.message))
                            if (errorCallback) {
                                errorCallback()
                            }
                        }
                    }).catch(e => {
                        toast.error(e);
                        if (errorCallback) {
                            errorCallback()
                        }
                    }).finally(() => setLoading(null));
                });
            }
        }

        if (props.request && props.token && props.id_table && props.id_establishment && props.request) {
            document.title = `${props.name_establishment} - ${i18next.t('global.title')}`
            props.request("menu/get", null, result => {
                setMenu(result.data)
            }, i18next.t('global.loadingMenu'))
        }
    }, [props.token, props.id_table, props.id_establishment, props.request])

    useEffect(() => {

        if(!props.name_establishment)
            document.title = `${i18next.t('global.loadingMenu')}`

        if ((props.language === "ar" || props.language === "he")) {
            document.querySelector("body").style.direction = "rtl"
        } else {
            document.querySelector("body").style.direction = "ltr"
        }
    }, [props.language])

    useEffect(() => {
        if(props.name_establishment)
            document.title = `${props.name_establishment} - ${i18next.t('global.title')}`
    }, [props.name_establishment])

    return <Outlet />
}
const mapStateToProps = (state) => ({
    id_table: state.global.id_table,
    id_establishment: state.global.id_establishment,
    menu: state.global.menu,
    timestamp: state.global.timestamp,
    token: state.global.token,
    name_establishment: state.global.name_establishment,
    request: state.request.request,
    colors: state.colors.colors,
});

export default connect(mapStateToProps)(Middleware)