import {
    SET_ERROR,
    SET_ID_ESTABLISHMENT,
    SET_ID_TABLE,
    SET_LOADING,
    SET_LOGO_ESTABLISHMENT,
    SET_MENU,
    SET_NAME_ESTABLISHMENT,
    SET_NAME_TABLE,
    SET_TIMESTAMP,
    SET_TOKEN,
    SET_CART,
    SET_LANGUAGE,
    SET_CURRENCY,
    SET_CURRENT_MENU
} from "../actions/globalActions";

const timestamp = localStorage.getItem("timestamp");
const name_table = localStorage.getItem("name_table");
const id_establishment = localStorage.getItem("id_establishment");
const id_table = localStorage.getItem("id_table");
const token = localStorage.getItem("token");
const name_establishment = localStorage.getItem("name_establishment");
const logo_establishment = localStorage.getItem("logo_establishment");
const language = localStorage.getItem("language");
const currency = localStorage.getItem("currency");

const initialState = {
    id_establishment: (id_establishment === null || id_establishment === "null") ? null : localStorage.getItem("id_establishment"),
    id_table: (id_table === null || id_table === "null") ? null : localStorage.getItem("id_table"),
    token: (token === null || token === "null") ? null : localStorage.getItem("token"),
    name_establishment: (name_establishment === null || name_establishment === "null") ? null : localStorage.getItem("name_establishment"),
    logo_establishment: (logo_establishment === null || logo_establishment === "null") ? null : localStorage.getItem("logo_establishment"),
    name_table: (name_table === null || name_table === "null") ? null : localStorage.getItem("name_table"),
    timestamp: (timestamp === null || timestamp === "null") ? null : localStorage.getItem("timestamp"),
    currency: (currency === null || currency === "null") ? null : localStorage.getItem("currency"),
    language: (language === null || language === "null") ? "fr" : localStorage.getItem("language"),
    menu: null,
    error: null,
    loading: false,
    cart: false,
    currentMenu: null
};

export default function globalReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_MENU : {
            return {
                ...state,
                currentMenu: action.value
            };
        }
        case SET_CURRENCY : {
            return {
                ...state,
                currency: action.value
            };
        }
        case SET_ID_ESTABLISHMENT : {
            return {
                ...state,
                id_establishment: action.value
            };
        }
        case SET_CART : {
            return {
                ...state,
                cart: action.value
            };
        }
        case SET_LANGUAGE : {
            return {
                ...state,
                language: action.value
            };
        }
        case SET_TOKEN: {
            return {
                ...state,
                token: action.value
            };
        }
        case SET_TIMESTAMP: {
            return {
                ...state,
                timestamp: action.value
            };
        }
        case SET_LOGO_ESTABLISHMENT: {
            return {
                ...state,
                logo_establishment: action.value
            };
        }
        case SET_NAME_ESTABLISHMENT: {
            return {
                ...state,
                name_establishment: action.value
            };
        }
        case SET_NAME_TABLE: {
            return {
                ...state,
                name_table: action.value
            };
        }
        case SET_ID_TABLE : {
            return {
                ...state,
                id_table: action.value
            };
        }
        case SET_MENU : {
            return {
                ...state,
                menu: action.value
            };
        }
        case SET_ERROR : {
            return {
                ...state,
                error: action.value
            };
        }
        case SET_LOADING : {
            return {
                ...state,
                loading: action.value
            };
        }
        default:
            return state
    }
}
