import {connect} from "react-redux";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import i18next from "i18next";
import {AssetsLink} from "../../helper/global";
import Link from "@mui/material/Link";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AddIcon from '@mui/icons-material/Add';
import Chip from "@mui/material/Chip";
import Backdrop from "@mui/material/Backdrop";
import ColoredLinearProgress from "./ColoredLinearProgress";
import {toast} from "react-toastify";
import {setCart} from "../../../redux/actions/globalActions";
import PreloadImage from 'react-preload-image'

const Article = (props) => {

    const {article, key} = props;
    const [quantity, setQuantity] = useState(1)
    const [showSizes, setShowSizes] = useState(false)
    const [selectedSize, setSelectedSize] = useState(null)
    const [additionnalPrice, setAdditionnalPrice] = useState(0)
    const [choiceSofts, setChoiceSofts] = useState(false)
    const [selectedSofts, setSelectedSofts] = useState([])
    const [loadingBackdrop, setLoadingBackdrop] = useState(false)

    const removeSoft = choice => {
        const index = selectedSofts.indexOf(choice);
        selectedSofts.splice(index, 1)
        setSelectedSofts([...selectedSofts]);
    }

    const addToCart = () => {
        setLoadingBackdrop(true)

        const choices = selectedSofts.map(s => s.id_article).join(",");

        const data = {
            id_article: article.id_article,
            quantity
        }

        if (choices.length)
            data.choices = choices

        if (selectedSize) {
            data.id_menu_choice = selectedSize.id_menu_choice;
            data.id_article_choice = selectedSize.id_article;
        }

        props.request("/cart/add", data, result => {

            toast.success(i18next.t("global.infoCart"), {
                onClick: () => setCart(true),
                toastId: "infoCart",
                autoClose: 20000000,
            })

            setTimeout(() => {
                setCart(true)
            }, 3000)


            setLoadingBackdrop(false);
            setChoiceSofts(false)
            setShowSizes(false)
            setQuantity(1)
            setSelectedSize(null)
            setSelectedSofts([])

        })
    }

    return <React.Fragment key={key}>

        <Backdrop
            style={{zIndex: 10}}
            open={loadingBackdrop}
        ><ColoredLinearProgress style={{width: "100%"}}/></Backdrop>

        <Grid item xs={12}>
            <Paper elevation={1} className={"paperArticle"}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={8} style={{position: "relative"}}>
                        <Grid container style={{minHeight: "120px"}} direction="column" alignItems="stretch"
                              justifyContent={"space-around"}>
                            <Grid item className={"nameArticle"}>
                                {article.name_article}
                            </Grid>
                            {article.desc_article.length > 0 ? (
                                <Grid item className={"descArticle"}>
                                    {article.desc_article}
                                </Grid>
                            ) : (
                                <Grid item className={"descArticle"}>
                                    {i18next.t('global.noDesc')}
                                </Grid>
                            )}

                            <Grid item className={"priceArticle"}>
                                <Grid container direction="row"
                                      justifyContent="space-around"
                                      alignItems="center">
                                    <Grid item xs={4}>
                                        {((parseFloat(article.price_article) + additionnalPrice + selectedSofts.reduce((prev, current) => prev + parseFloat(current.price_article), 0)) * quantity).toFixed(2)}{props.currency}
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction="row"
                                              justifyContent="center"
                                              alignItems="center"
                                              spacing={1}>
                                            <Grid item><IconButton onClick={() => setQuantity(quantity - 1)}
                                                                   disabled={quantity <= 1}
                                                                   className={quantity <= 1 ? "quantityButton quantityButtonDisabled" : "quantityButton"}><HorizontalRuleIcon
                                                className={"quantityIcon"}/></IconButton></Grid>
                                            <Grid item><span className={"quantity"}>{quantity}</span></Grid>
                                            <Grid item><IconButton onClick={() => setQuantity(quantity + 1)}
                                                                   className={"quantityButton"}><AddIcon
                                                className={"quantityIcon"}/></IconButton></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={"pictureArticleContainer"}>
                            <Link onClick={() => {
                                if((showSizes || choiceSofts) && selectedSofts.length === 0) {
                                    setChoiceSofts(false)
                                    setShowSizes(false)
                                    setSelectedSize(null)
                                    setSelectedSofts([])
                                }   else if (article.choices && !showSizes) {
                                    setShowSizes(true)
                                } else if (parseInt(article.soft_count_article) > 0 && !choiceSofts) {
                                    setChoiceSofts(true)
                                } else if(!choiceSofts && !showSizes) {
                                    addToCart()
                                }   else {
                                    setChoiceSofts(false)
                                    setShowSizes(false)
                                    setSelectedSize(null)
                                    setSelectedSofts([])
                                }
                            }}>
                                <PreloadImage
                                    innerStyle={{
                                        position: "relative",
                                        backgroundSize: "contain"
                                    }}
                                    className="pictureArticle"
                                    src={`${AssetsLink}${article.article_img.replace("'", "%27")}`}
                                    lazy
                                />
                                {
                                    article.choices ?
                                        !showSizes ? (<div className={"buttonArticle"}>{i18next.t('global.orderSizes')}</div>
                                        ) : (<div className={"buttonArticle"}>{i18next.t('global.hideOrderSizes')}</div>
                                        ) : (<div className={"buttonArticle"}>{choiceSofts ? i18next.t('global.closeSofts') : i18next.t('global.order')}</div>)
                                }
                            </Link>
                        </Paper>
                    </Grid>
                </Grid>

                {
                    article.choices && showSizes && <>

                        <Grid item xs={12} className={"buttonSeparator"}
                              style={{marginBottom: "1vh", marginTop: "2vh"}}/>

                        <div className={"sizesSelector"}>
                            <Grid container spacing={1} justifyContent={"center"}>
                                {
                                    article.choices.map(choice => <Grid key={choice.name_article} item>
                                        <Chip
                                            className={selectedSize && choice.id_article === selectedSize.id_article ? "sizesChoiceSelected" : "sizesChoice"}
                                            label={`${choice.name_article}`} variant="outlined" onClick={() => {
                                            if (selectedSize && choice.id_article === selectedSize.id_article) {
                                                setSelectedSize(null)
                                                setAdditionnalPrice(0.00)
                                                setSelectedSofts([])
                                            } else {
                                                setSelectedSize(choice)
                                                setAdditionnalPrice(parseFloat(choice.additionnal_cost))
                                            }
                                        }}/>
                                    </Grid>)
                                }

                                {
                                    selectedSize && parseInt(article.soft_count_article) === 0 && (
                                        <>
                                            <Grid item xs={12} className={"buttonSeparator"}
                                                  style={{marginBottom: "0", marginTop: "1vh"}}/>

                                            <Link onClick={addToCart} underline={"none"}>
                                                <div className={"buttonAdd"}>{i18next.t('global.addCart')}</div>
                                            </Link>

                                        </>
                                    )
                                }

                            </Grid>

                        </div>
                    </>
                }

                {
                    (choiceSofts || selectedSize) &&
                    <>
                        <div className={"sizesSelector"}>
                            <Grid container spacing={1} justifyContent={"center"}>
                                {
                                    selectedSofts.length < parseInt(article.soft_count_article) && (
                                        <>
                                            <Grid item xs={12} className={"buttonSeparator"}
                                                  style={{marginBottom: "0", marginTop: "2vh"}}/>
                                            <Grid item xs={12} className={"titleSofts"}>
                                                {
                                                    parseInt(article.soft_count_article) === 1 ? i18next.t('global.softChoice') : `${i18next.t('global.softsChoice')} (${article.soft_count_article})`
                                                }
                                            </Grid>
                                            {
                                                Object.keys(props.menu['CHOICES']).map(choiceIdx => {
                                                    const choice = props.menu['CHOICES'][choiceIdx];

                                                    return (
                                                        <Grid key={choice.name_article} item>
                                                            <Chip
                                                                className={"sizesChoice"}
                                                                label={parseFloat(choice.price_article) === 0 ? choice.name_article : `${choice.name_article} (+${choice.price_article}${props.currency})`}
                                                                variant="outlined"
                                                                onClick={() => {
                                                                    if (selectedSofts.length < parseInt(article.soft_count_article)) {
                                                                        selectedSofts.push(choice)
                                                                        setSelectedSofts([...selectedSofts])
                                                                    }
                                                                }}/>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }

                                {
                                    selectedSofts.length > 0 && (
                                        <>
                                            <Grid item xs={12} className={"titleSofts"} style={{
                                                borderTop: "2px solid rgba(246, 188, 65, 0.39)",
                                                marginTop: "2vh"
                                            }}>
                                                {i18next.t('global.selectedSofts')}
                                            </Grid>

                                            {
                                                selectedSofts.map((choice) => <Grid item><Chip
                                                    className={"sizesChoiceSelected"}
                                                    label={parseFloat(choice.price_article) === 0 ? choice.name_article : `${choice.name_article} (+${choice.price_article}${props.currency})`}
                                                    variant="outlined" onClick={() => {
                                                    removeSoft(choice)
                                                }}/></Grid>)
                                            }

                                            <Grid item xs={12} className={"buttonSeparator"}
                                                  style={{marginBottom: "0", marginTop: "1vh"}}/>

                                            <Link onClick={addToCart} underline={"none"}>
                                                <div className={"buttonAdd"}>{i18next.t('global.addCart')}</div>
                                            </Link>

                                        </>
                                    )
                                }

                            </Grid>
                        </div>
                    </>
                }
            </Paper>
        </Grid>
    </React.Fragment>
}

const mapStateToProps = (state) => ({
    menu: state.global.menu,
    currency: state.global.currency,
    request: state.request.request
});

export default connect(mapStateToProps)(Article)