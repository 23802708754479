import { combineReducers } from 'redux'
import globalReducer from "./globalReducer";
import requestReducer from "./requestReducer";
import sosReducer from "./sosReducer";
import colorsReducer from "./colorsReducer";

const rootReducer = combineReducers({
    global: globalReducer,
    request: requestReducer,
    sos: sosReducer,
    colors: colorsReducer
}) ;

export default rootReducer
