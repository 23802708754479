import React from 'react';
import {connect} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Init from "./components/Init";
import Home from "./components/Home";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18next from "i18next";
import fr from "./../locales/fr";
import en from "./../locales/en";
import es from "./../locales/es";
import ar from "./../locales/ar";
import cn from "./../locales/cn";
import de from "./../locales/de";
import he from "./../locales/he";
import it from "./../locales/it";
import jp from "./../locales/jp";
import pt from "./../locales/pt";
import ru from "./../locales/ru";
import Error from "./components/shared/Error";
import Loader from "./components/shared/Loader";
import Cart from "./components/shared/Cart";
import {ThemeProvider} from "@emotion/react";
import {createTheme} from "@mui/material";
import orange from "@mui/material/colors/orange";
import Menu from "./components/Menu";
import Middleware from "./helper/Middleware";
import Notification from "./components/Notification";
// import OneSignal from 'react-onesignal';


i18next.init({
    lng: localStorage.getItem("language") === null ? "fr" : localStorage.getItem("language"),
    debug: false,
    fallbackLng: "fr",
    resources: {
        fr: {
            translation: fr
        },
        gb: {
            translation: en
        },
        es: {
            translation: es
        },
        cn: {
            translation: cn
        },
        de: {
            translation: de
        },
        he: {
            translation: he
        },
        it: {
            translation: it
        },
        jp: {
            translation: jp
        },
        pt: {
            translation: pt
        },
        ru: {
            translation: ru
        },
        ar: {
            translation: ar
        }
    }
});

const App = (props) => {

    const theme = createTheme({
        status: {
            danger: orange[500],
        }, barColorPrimary: {
            backgroundColor: '#B2DFDB',
        }
    });

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <Loader/>
                <ToastContainer/>
                <BrowserRouter>
                    <Notification/>
                    {!props.error && <Cart/>}
                    <Routes>
                        <Route path="/init/:token" element={<Init/>}/>
                        <Route element={<Middleware/>}>
                            <Route default index path="*" element={<Home/>}/>
                            <Route path="/menu/:category" element={<Menu/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
                <Error/>
            </ThemeProvider>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    id_table: state.global.id_table,
    id_establishment: state.global.id_establishment,
    token: state.global.token,
    language: state.global.language,
    name_establishment: state.global.name_establishment,
    request: state.request.request,
    error: state.global.error
});

export default connect(mapStateToProps)(App)

