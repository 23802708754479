import {Store} from "../Store";

export const SET_SOS = "SET_SOS"
export const SET_SOS_CHOICE = "SET_SOS_CHOICE"

export const setSos = (value) => {
    Store.dispatch({ type: SET_SOS, value})
}

export const setSosChoice = (value) => {
    Store.dispatch({ type: SET_SOS_CHOICE, value})
}
