import React, {useEffect, useState} from 'react';
import {getTokenFb, messaging} from '../helper/firebase';
import {toast} from "react-toastify";
import {isSupported, onMessage} from "firebase/messaging";
import {setCart} from "../../redux/actions/globalActions";
import i18next from "i18next";
import {connect} from "react-redux";

const Notification = (props) => {

    const [tokenNotifs, setTokenNotifs] = useState(false);

    React.useEffect(() => {
        if (tokenNotifs && props.id_establishment && props.request) {
            props.request("Auth/registerToken", {tokenNotifs}, result => console.log(result))
        }
    }, [tokenNotifs])

    const notificationGranted = () => {
        getTokenFb(setTokenNotifs).then(() => {
        });

        onMessage(messaging, (payload) => {
            try {
                const parse = JSON.parse(payload.data.data);
                if (parse.payload) {
                    const data = parse.payload;
                    console.log(data)
                    if (data.cart === "open") {
                        setCart(true)
                    } else if (data.cart === "close") {
                        setCart(false)
                    }
                    if (data.i18n)
                        toast.info(i18next.t(data.i18n))
                }
            } catch (e) {
                console.log("Cannot parse firebase message")
            }
        });
    }

    const requestPermission = () => {
        isSupported().then((support) => {
            if (support) {
                try {
                    window.Notification.requestPermission()
                        .then((p) => {
                            if (navigator.permissions) {
                                navigator.permissions.query({name: 'notifications'}).then(function (notificationPerm) {
                                    notificationPerm.onchange = function () {
                                        if (notificationPerm.state === "granted") {
                                            notificationGranted()
                                        }
                                    };
                                    if (notificationPerm.state === "granted") {
                                        notificationGranted()
                                    }
                                });
                            }
                        })
                } catch (error) {
                    console.log(error)
                }
            }
        })
    }

    useEffect(() => {
        requestPermission()
    }, [])

    return ""
}

const mapStateToProps = (state) => ({
    request: state.request.request,
    id_establishment: state.global.id_establishment
});

export default connect(mapStateToProps)(Notification)